// @flow
import * as React from 'react';
type Props = {

};
export const Footer = (props: Props) => {
    return (
        <div>
            Footer
        </div>
    );
};