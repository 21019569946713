// @flow
import * as React from 'react';
import { WordMemory } from '../../Components/WordMemory/WordMemory';
type Props = {

};
export const WordMemPage = (props: Props) => {
    return (
        <div>
            <WordMemory />
        </div>
    );
};